"use client";

import { FormEvent, useEffect, useState } from "react";
import { useRouter } from "next/navigation";

import styles from "./Form.module.scss";
import { useClientLocale } from "@utils/Localization/useClientLocale";
import useTranslations from "@utils/Localization/useTranslations";
import z from "zod";
import { useFormData } from "@utils/Service/FormDataContext";
import { ServiceHandler } from "@utils/Service/ServiceHandler";
import loggingAction from "@utils/Service/loggingAction";
import { pages } from "@utils/OldService/pages";
import FormSetErrors from "@utils/Service/FormSetErrors";
import Dropdown from "@components/OldService/UI/Dropdown/Dropdown";
import FormButton from "@components/OldService/Service/Form/FormButton";
import { SendErrorReport } from "@utils/Mail/SendErrorReport";
import { sendServiceEmail, sendServiceZendeskCase } from "@utils/Mail/actions/service";
import { Channel } from "@constants/enums";
import ContactInformation from "@components/OldService/Service/Form/ContactInformation";
import Service from "@components/OldService/Service/Form/Service";
import AdditionalInformation from "@components/OldService/Service/Form/AdditionalInformation";
import ComplaintEntries from "@components/OldService/Service/Form/ComplaintEntries";

export default function ServiceForm({ devMode }: { devMode: boolean }) {
  const { formData, componentIndex, clearFormData, setErrors, removeAllFiles, setComponentIndex } = useFormData();
  const router = useRouter();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");

  const locale = useClientLocale();
  const { t } = useTranslations(locale);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (loading) return;

    setLoading(true);

    //await loggingAction(`service submitted started: ${JSON.stringify(formData, null, 2)}`);

    try {
      new ServiceHandler(formData);
      ServiceHandler.validateFormData();
      ServiceHandler.checkIfProductExistAndAddExtraProductData();

      const { data } = await ServiceHandler.submit();

      if (!data) {
        throw new Error("Something went wrong");
      }

      await loggingAction(`service submitted: ${JSON.stringify(data.uuid, null, 2)}`);

      if (devMode) {
        console.log("sending to zendesk");
        await sendServiceEmail({
          data,
          locale,
          channel: Channel.internal,
          options: {
            toEmail: "admin@qlight.no"
          }
        });
      } else {
        console.log("sending to zendesk");
        await sendServiceZendeskCase({
          data,
          locale,
          channel: Channel.internal
        });
      }

      await loggingAction(`internal mail sent: ${JSON.stringify(data.uuid, null, 2)}`);

      clearFormData();
      router.push(`${pages.service}/${data.uuid}`, {});
    } catch (e) {
      console.log("an unexpected error occurred", e);
      setLoading(false);
      if (e instanceof z.ZodError) {
        FormSetErrors({
          e,
          setErrors
        });

        if (e.errors[0].path[0]) {
          setComponentIndex(componentIndex);
        }
      } else {
        setError("an unexpected error occurred");
        console.error("server might be down");
        await loggingAction(JSON.stringify(e));
      }

      await SendErrorReport(e, formData);

      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  useEffect(() => {
    removeAllFiles();
  }, []);

  return (
    <>
      {error && (
        <div className={styles.message}>
          <span className={styles.error}>{error}</span>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <Dropdown
          title={`1 ${t("return")} / ${t("complaint")}`}
          open={componentIndex === 1}
          index={1}
          query={"service"}
        >
          <Service />
          <FormButton
            button={{
              type: "button",
              text: "Neste",
              query: "contactInformaation",
              style: styles.next
            }}
            index={1}
          />
        </Dropdown>
        <Dropdown
          title={`2 ${t("contactInformation")} - ${t("customer")}`}
          open={componentIndex === 2}
          index={2}
          query={"contactInformation"}
        >
          <ContactInformation />
          <FormButton
            button={{
              type: "button",
              text: "Neste",
              query: "product",
              style: styles.next
            }}
            index={2}
          />
        </Dropdown>
        <Dropdown title={`3 ${t("product")}`} open={componentIndex === 3} index={3} query={"product"}>
          <ComplaintEntries />
          <FormButton
            button={{
              type: "button",
              text: "Neste",
              query: "additionalInformation",
              style: styles.next
            }}
            index={3}
          />
        </Dropdown>
        <Dropdown
          title={`4 ${t("additionalInformation")}`}
          open={componentIndex === 4}
          index={4}
          query={"additionalInformation"}
        >
          <AdditionalInformation />
          <FormButton
            button={{
              type: "submit",
              text: "Send",
              query: "confirmation",
              style: styles.submit
            }}
            index={4}
          />
        </Dropdown>

        {loading && (
          <div className={styles.loading}>
            <div className={styles.svg}>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#0a0a0a" viewBox="0 0 256 256">
                <path
                  d={
                    `M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,` +
                    `48Zm-96,85.15L52.57,64H203.43ZM98.71,128,40,181.81V74.19Zm11.84,10.85,12,11.05a8,8,0,` +
                    `0,0,10.82,0l12-11.05,58,53.15H52.57ZM157.29,128,216,74.18V181.82Z`
                  }
                ></path>
              </svg>
            </div>
          </div>
        )}
      </form>
    </>
  );
}
