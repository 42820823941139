import styles from "./Number.module.css";
import React, { ChangeEvent } from "react";

interface InputProps {
  className: string;
  name: string;
  type: "number";
  value?: string | number;
  required?: boolean;
  readOnly?: boolean;
  autoComplete?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  settings?: {
    min?: number;
    max?: number;
    step?: number;
  };
}

export default function Number({
  className,
  name,
  type = "number",
  value = "1",
  required,
  readOnly,
  autoComplete,
  onChange,
  disabled,
  settings
}: InputProps) {
  className = [styles.container, className].join(" ");

  const step = settings?.step || 1;
  const min = settings?.min === 0 ? 0 : 1;
  const max = settings?.max;

  const incrementQuantity = () => {
    if (typeof value === "string") {
      value = parseInt(value || min.toString());
    }

    if (max && value >= max) {
      return;
    }

    onChange({
      target: {
        name: name,
        value: value + step,
        type: type
      }
    } as unknown as ChangeEvent<HTMLInputElement>);
  };

  const decrementQuantity = () => {
    if (typeof value === "string") {
      value = parseInt(value || min.toString());
    }

    onChange({
      target: {
        name: name,
        value: value > min ? value - step : min,
        type: type
      }
    } as unknown as ChangeEvent<HTMLInputElement>);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      e.target.value = min.toString();
    }

    if (e.target.value.length > 1 && e.target.value[0] === "0") {
      e.target.value = e.target.value.slice(1);
    }

    if (max && parseInt(e.target.value) > max) {
      e.target.value = max.toString();
    }

    onChange(e);
  };

  className = [styles.container, className].join(" ");

  return (
    <div className={className}>
      <button id={`decrement-${name}`} type={"button"} className={styles.decrement} onClick={() => decrementQuantity()}>
        -
      </button>

      <input
        className={styles.number}
        type={type}
        min={settings?.min === 0 ? 0 : 1}
        max={settings?.max}
        step={settings?.step || 1}
        id={name}
        name={name}
        value={value}
        required={required}
        readOnly={readOnly}
        autoComplete={autoComplete ? "on" : "off"}
        onChange={handleChange}
        disabled={disabled}
      />

      <button id={`increment-${name}`} type={"button"} className={styles.increment} onClick={() => incrementQuantity()}>
        +
      </button>
    </div>
  );
}
