import styles from "./ComplaintEntry.module.scss";
import ComplaintEntry from "./ComplaintEntry";
import { useClientLocale } from "@utils/Localization/useClientLocale";
import useTranslations from "@utils/Localization/useTranslations";
import { useFormData } from "@utils/Service/FormDataContext";
import Button from "@components/OldService/UI/Button/Button";

export default function ComplaintEntries() {
  const { formData, addComplaintEntryDefault } = useFormData();

  const { t } = useTranslations(useClientLocale());

  return (
    <div id={"complaintEntries"} className={styles.container}>
      {formData?.complaintEntries?.map((complaintEntry, index) => (
        <div key={index} className={styles.content0}>
          <ComplaintEntry complaintEntry={complaintEntry} index={index} />
        </div>
      ))}

      <div className={styles.add}>
        <Button id={"addNewProduct"} type={"button"} design={"secondary"} onClick={addComplaintEntryDefault}>
          + {t("addNewProduct")}
        </Button>
      </div>
    </div>
  );
}
