export const sliceToLastFullWord = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) return text;
  if (text[maxLength] === " " || !text[maxLength]) {
    return text.slice(0, maxLength);
  } else {
    return text.slice(0, text.lastIndexOf(" ", maxLength));
  }
};

export const convertToCamelCase = (text: string) => {
  return text
    .split(" ")
    .map((word, index) => {
      const lower = word.toLowerCase();
      return index === 0 ? lower : lower.charAt(0).toUpperCase() + lower.slice(1);
    })
    .join("");
};
