export default class SessionStorage {
  // Save an item to session storage
  static set<T>(key: string, value: T): void {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(`Failed to save item to session storage: ${error}`);
    }
  }

  // Retrieve an item from session storage
  static get<T>(key: string): T | null {
    try {
      const item = sessionStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.error(`Failed to retrieve item from session storage: ${error}`);
      return null;
    }
  }

  // Remove an item from session storage
  static remove(key: string): void {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {
      console.error(`Failed to remove item from session storage: ${error}`);
    }
  }

  // Check if key exists in session storage
  static keyExists(key: string): boolean {
    try {
      return sessionStorage.getItem(key) !== null;
    } catch (error) {
      console.error(`Failed to check if key exists in session storage: ${error}`);
      return false;
    }
  }

  // Clear all items from session storage
  static clear(): void {
    try {
      sessionStorage.clear();
    } catch (error) {
      console.error(`Failed to clear session storage: ${error}`);
    }
  }
}
