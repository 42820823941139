import styles from "./Input.module.scss";
import Number from "./Number";
import { ChangeEvent } from "react";

interface InputProps {
  name: string;
  label: string;
  aside?: string;
  type?:
    | "text"
    | "textarea"
    | "tel"
    | "date"
    | "number"
    | "select"
    | "checkbox"
    | "radio"
    | "email"
    | "password"
    | "file";
  textArea?: {
    rows: number;
  };
  select?: {
    value: string;
    label: string;
    selected?: boolean;
    disabled?: boolean;
  }[];
  radio?: {
    value: string;
    checked: boolean;
    label: string;
  }[];
  file?: {
    multiple: boolean;
    label?: string;
  };
  number?: {
    min?: number;
    max?: number;
    step?: number;
  };
  value?: string | number | boolean;
  required?: boolean;
  readOnly?: boolean;
  autoComplete?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  className?: string;
  error?: string | null;
  disabled?: boolean;
  list?: string;
  accept?: string;
}

export default function Input({
  name,
  label,
  aside,
  type = "text",
  textArea,
  select,
  radio,
  file,
  number,
  value = "",
  required,
  readOnly,
  autoComplete,
  onChange,
  className,
  error,
  disabled,
  list,
  accept
}: InputProps) {
  if (disabled) {
    onChange = () => console.log("you cannot wield it");
  }

  return (
    <div className={`${styles.container} ${className || ""}`}>
      <div>
        <div className={styles.row}>
          {!file?.label && (
            <label htmlFor={name} className={styles.label}>
              {label}
            </label>
          )}
          {file?.label && <span className={styles.label}>{label}</span>}
          {required && <span className={styles.required}>*</span>}
          {aside && <aside className={styles.aside}>{aside}</aside>}
        </div>
      </div>

      {type === "text" && (
        <input
          className={styles.input}
          type={type}
          id={name}
          name={name}
          value={value as string}
          required={required}
          readOnly={readOnly}
          autoComplete={autoComplete ? "on" : "off"}
          onChange={onChange}
          disabled={disabled}
          list={list}
        />
      )}

      {type === "email" && (
        <input
          className={styles.input}
          type={type}
          id={name}
          name={name}
          value={value as string}
          required={required}
          readOnly={readOnly}
          autoComplete={autoComplete ? "on" : "off"}
          onChange={onChange}
          disabled={disabled}
        />
      )}

      {type === "tel" && (
        <input
          className={styles.input}
          type={type}
          id={name}
          name={name}
          value={value as string}
          required={required}
          readOnly={readOnly}
          autoComplete={autoComplete ? "on" : "off"}
          onChange={onChange}
          disabled={disabled}
        />
      )}

      {type === "number" && (
        <Number
          className={styles.number}
          type={type}
          settings={number}
          name={name}
          value={value as number}
          required={required}
          readOnly={readOnly}
          autoComplete={autoComplete}
          onChange={onChange}
          disabled={disabled}
        />
      )}

      {type === "textarea" && (
        <textarea
          className={`${styles.input} ${styles.textarea}`}
          id={name}
          name={name}
          value={value as string}
          required={required}
          readOnly={readOnly}
          autoComplete={autoComplete ? "on" : "off"}
          rows={textArea?.rows}
          onChange={onChange}
          disabled={disabled}
        />
      )}

      {type === "select" && (
        <select
          className={styles.input}
          id={name}
          name={name}
          value={(value as string) || ""}
          required={required}
          autoComplete={autoComplete ? "on" : "off"}
          onChange={onChange}
        >
          {select?.map((item, index) => {
            return (
              <option key={index} value={item.value} disabled={item.disabled}>
                {item.label}
              </option>
            );
          })}
        </select>
      )}

      {type === "radio" && (
        <div className={styles.radio}>
          {radio?.map((item, index) => {
            return (
              <div key={index}>
                <input
                  type={type}
                  id={item.value}
                  name={name}
                  value={item.value}
                  required={required}
                  autoComplete={autoComplete ? "on" : "off"}
                  checked={item.checked}
                  onChange={onChange}
                  disabled={disabled}
                />
                <label htmlFor={item.value} className={styles.radioLabel}>
                  {item.label}
                </label>
              </div>
            );
          })}
        </div>
      )}

      {type === "file" && (
        <>
          <input
            className={styles.input}
            style={{ display: file?.label ? "none" : "block" }}
            type={type}
            id={name}
            name={name}
            required={required}
            autoComplete={autoComplete ? "on" : "off"}
            multiple={file?.multiple}
            onChange={onChange}
            disabled={disabled}
            accept={accept}
          />
          {file?.label && (
            <label htmlFor={name} className={styles.fileLabel} id={`${name}-label`}>
              {file.label}
            </label>
          )}
        </>
      )}

      {type === "checkbox" && (
        <input
          className={styles.input}
          type={type}
          id={name}
          name={name}
          checked={value as boolean}
          required={required}
          autoComplete={autoComplete ? "on" : "off"}
          onChange={onChange}
          disabled={disabled}
        />
      )}

      {(error && <p className={styles.error}>{error}</p>) || null}
    </div>
  );
}
