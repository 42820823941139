import {z} from "zod";
import {Channel} from "./channelSchema";

export const Request = z.object({
  locale: z.string(),
  referer: z.string().optional(),
  channel: Channel.optional(),
  subject: z.string().optional(),
  fromEmail: z.string().optional(),
  toEmail: z.string().optional(),
  replyTo: z.string().optional(),
  preventEmailSending: z.boolean().optional(),
});