import * as z from "zod";

interface FormSetErrorsProps {
  e: z.ZodError;
  checkAll?: boolean;
  setErrors: (
    errors: { [key: string]: string } | ((prev: { [key: string]: string }) => { [key: string]: string })
  ) => void;
}

export default function FormSetErrors({ e, checkAll, setErrors }: FormSetErrorsProps) {
  for (let i = 0; i < e.errors.length; i++) {
    if (e.errors[i].path[0] === "complaintEntries") {
      if (e.errors[i].path[2] === "lengths") {
        // @ts-ignore
        if (e.errors[i].received === "undefined" && !checkAll) {
          return;
        }

        const index = e.errors[i].path[1];
        const lengthIndex = e.errors[i].path[3];
        const name = e.errors[i].path[4];
        const key = `${name}-${index}-${lengthIndex}`;
        const message = e.errors[i].message;

        setErrors((prev) => ({ ...prev, [key]: message }));
      } else {
        // @ts-ignore
        if (e.errors[i].received === "undefined" && !checkAll) {
          return;
        }

        const index = e.errors[i].path[1];
        const name = e.errors[i].path[2];
        const key = `${name}-${index}`;
        const message = e.errors[i].message;

        setErrors((prev) => ({ ...prev, [key]: message }));
      }
    } else {
      // @ts-ignore
      if (e.errors[i].received === "undefined" && !checkAll) {
        return;
      }

      setErrors((prev) => ({ ...prev, [e.errors[i].path[0]]: e.errors[i].message }));
    }
  }
}
