export type Locale = "nb" | "en"; // used for simple localization on /service page
export const defaultLocale: Locale = "en";
export type translations = { [key: string]: string };

export const languages = [
  {
    id: 1,
    name: "English",
    isoKey: "en"
  },
  {
    id: 2,
    name: "Norwegian",
    isoKey: "nb"
  },
  {
    id: 3,
    name: "Danish",
    isoKey: "da"
  },
  {
    id: 4,
    name: "German",
    isoKey: "de"
  },
  {
    id: 5,
    name: "Swedish",
    isoKey: "sv"
  },
  {
    id: 6,
    name: "French",
    isoKey: "fr"
  },
  {
    id: 7,
    name: "Icelandic",
    isoKey: "is"
  }
] as Language[];

export interface Language {
  id: number;
  name: string;
  isoKey: string;
}

export function getLanguageName(language: Locale): string {
  return languages.find((l) => l.isoKey === language)?.name || "";
}
