import { z } from "zod";

export const awsFileSchema = z.object({
  id: z.string().optional(),
  name: z.string().optional(),
  fileType: z.string().optional(),
  fileExtension: z.string().optional(),
  url: z.string(),
  productId: z.number().optional(),
  settings: z
    .object({
      Key: z.string().optional(),
      ETag: z.string().optional(),
      Bucket: z.string().optional(),
      $metadata: z.any().optional()
    })
    .optional(),
  type: z.string().optional(),
  public: z.boolean().optional(),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional()
});

export const fileSchema = z.object({
  name: z.string(),
  url: z.string().url().optional()
});
