"use client";

import { useParams } from "next/navigation";
import { defaultLocale, Locale } from "@ts-types/locale";

export const useClientLocale = (): Locale => {
  const params = useParams();
  const locale = params?.lang;

  return locale && !Array.isArray(locale) ? (locale as Locale) : defaultLocale;
};
