import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/[lang]/(service)/service/page.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/OldService/Nav/LocaleSwitch.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/OldService/Service/Form/ServiceForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FormDataProvider"] */ "/app/src/utils/Service/FormDataContext.tsx");
