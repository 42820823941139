"use client";

import styles from "./FormButton.module.css";
import useTranslations from "@utils/Localization/useTranslations";
import { useClientLocale } from "@utils/Localization/useClientLocale";
import { FormButtonProp } from "@utils/Service/interfaces";
import { useFormData } from "@utils/Service/FormDataContext";
import Button from "@components/OldService/UI/Button/Button";

interface FormButtonProps {
  button: FormButtonProp;

  index: number;
}

export default function FormButton({ button, index }: FormButtonProps) {
  const { checkErrors, setComponentIndex } = useFormData();

  const { t } = useTranslations(useClientLocale());

  const handleNext = () => {
    if (checkErrors(true)) {
      setComponentIndex(index + 1);
    }
  };

  const handleBack = () => {
    setComponentIndex(index - 1);
  };

  return (
    <div>
      {button?.type === "button" && (
        <div className={styles.spaceBetween}>
          {index !== 0 && (
            <div className={styles.back}>
              <Button id={"back"} type={"button"} design={"primary"} onClick={handleBack}>
                {t("back")}
              </Button>
            </div>
          )}
          <div className={styles.next}>
            <Button id={"next"} type={"button"} design={"primary"} onClick={handleNext}>
              {button?.text === "Neste" ? t("next") : button?.text === "send" ? t("send") : button?.text}
            </Button>
          </div>
        </div>
      )}
      {button?.type === "submit" && (
        <div className={styles.spaceBetweenSubmit}>
          <div className={styles.back}>
            <Button id={"back"} type={"button"} design={"primary"} onClick={handleBack}>
              {t("back")}
            </Button>
          </div>
          <div className={styles.submit}>
            <Button id={"next"} type={button?.type} design={"primary"}>
              {button?.text === "Neste" ? t("next") : button?.text === "send" ? t("send") : button?.text}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
