// todo: maybe move this to server?

import { Locale } from "@ts-types/locale";
import { getTranslation } from "./getTranslation";
import { DictionaryType } from "@utils/Localization/loadTranslations";

export default function useTranslations(locale: Locale): { t: (key: keyof DictionaryType | string) => string } {
  const t = (key: string) => {
    const translation = getTranslation(locale, key);

    if (translation[key] === undefined) {
      if (process.env.NEXT_PUBLIC_DEVELOPMENT === "true") {
        console.log(key + " - needs translation");
        return key;
      }

      const translationEN = getTranslation("en", key);

      // todo: capitalize first letter in css
      if (translationEN[key]) {
        return translationEN[key].charAt(0).toUpperCase() + translationEN[key].slice(1);
      }
    }

    if (translation[key]) {
      return translation[key].charAt(0).toUpperCase() + translation[key].slice(1);
    }

    return key;
  };

  return { t };
}
