import { Locale, translations } from "@ts-types/locale";

import nbJSON from "./locales/nb/common.json";
import enJSON from "./locales/en/common.json";
// import svJSON from "../../public/locales/sv/common.json";
// import deJSON from "../../public/locales/de/common.json";
// import plJSON from "../../public/locales/pl/common.json";
// import frJSON from "../../public/locales/fr/common.json";
// import itJSON from "../../public/locales/it/common.json";
// import isJSON from "../../public/locales/is/common.json";
// import daJSON from "../../public/locales/da/common.json";
// import esJSON from "../../public/locales/es/common.json";

export type DictionaryType = typeof enJSON;

const loadTranslations = (locale: Locale): translations | undefined => {
  if (!locale) return undefined;

  try {
    let translations;

    switch (locale) {
      case "nb":
        translations = nbJSON;
        break;
      default:
        translations = enJSON;
        break;
    }

    if (translations === undefined) {
      return undefined;
    }
    return translations;
  } catch (error) {
    console.error(`Error reading translation file for locale ${locale}:`, error);
  }
};

export default loadTranslations;
