export interface CountryInformation {
  [key: string]: {
    name: string;
    language: string;
    countryCode: string;
    hasLocale: boolean;
    locale: string;
    addressLookup: boolean;
    zipLength?: {
      min: number;
      max: number;
    };
    enabled: boolean;
  };
}

export const countryNames: CountryInformation = {
  norway: {
    name: "Norway",
    language: "Norwegian",
    locale: "nb-NO",
    countryCode: "NO",
    hasLocale: true,
    addressLookup: true,
    zipLength: {
      min: 4,
      max: 4
    },
    enabled: true
  },
  denmark: {
    name: "Denmark",
    language: "Danish",
    locale: "da-DK",
    countryCode: "DK",
    hasLocale: false,
    addressLookup: true,
    zipLength: {
      min: 4,
      max: 4
    },
    enabled: true
  },
  sweden: {
    name: "Sweden",
    language: "Swedish",
    locale: "sv-SE",
    countryCode: "SE",
    hasLocale: false,
    addressLookup: true,
    zipLength: {
      min: 5,
      max: 5
    },
    enabled: true
  },
  finland: {
    name: "Finland",
    language: "Finnish",
    locale: "fi-FI",
    countryCode: "FI",
    hasLocale: false,
    addressLookup: true,
    zipLength: {
      min: 5,
      max: 5
    },
    enabled: true
  },
  netherlands: {
    name: "Netherlands",
    language: "Dutch",
    locale: "nl-NL",
    countryCode: "NL",
    hasLocale: false,
    addressLookup: true,
    zipLength: {
      min: 6,
      max: 6
    },
    enabled: false
  },
  germany: {
    name: "Germany",
    language: "German",
    locale: "de-DE",
    countryCode: "DE",
    hasLocale: false,
    addressLookup: true,
    zipLength: {
      min: 5,
      max: 5
    },
    enabled: true
  },
  unitedStates: {
    name: "United States",
    language: "English",
    locale: "en-US",
    countryCode: "US",
    hasLocale: false,
    addressLookup: true,
    enabled: false
  },
  belgium: {
    name: "Belgium",
    language: "Dutch",
    locale: "nl-BE",
    countryCode: "BE",
    hasLocale: false,
    addressLookup: true,
    enabled: false
  },
  faroeIslands: {
    name: "Faroe Islands",
    language: "Faroese",
    locale: "fo-FO",
    countryCode: "FO",
    hasLocale: false,
    addressLookup: true,
    enabled: true
  },
  greenland: {
    name: "Greenland",
    language: "Greenlandic",
    locale: "kl-GL",
    countryCode: "GL",
    hasLocale: false,
    addressLookup: true,
    enabled: true
  },
  iceland: {
    name: "Iceland",
    language: "Icelandic",
    locale: "is-IS",
    countryCode: "IS",
    hasLocale: false,
    addressLookup: true,
    zipLength: {
      min: 3,
      max: 3
    },
    enabled: true
  },
  svalbardAndJanMayen: {
    name: "Svalbard and Jan Mayen",
    language: "Norwegian",
    locale: "nb-SJ",
    countryCode: "SJ",
    hasLocale: false,
    addressLookup: true,
    enabled: true
  }
} as const;

export const countrySelectOptions = Object.values(countryNames)
  .filter(({ enabled }) => enabled)
  .map(({ name }) => ({
    label: name,
    value: name
  }));

export const getCountryCode = (countryName: string): string => {
  const country = Object.values(countryNames).find(({ name }) => name.toLowerCase() === countryName.toLowerCase());

  return country?.countryCode.toLowerCase() ?? "";
};

export const getCountryByEnabledAndHasAddressLookup = (countryName: string): string | boolean => {
  const country = Object.values(countryNames).find(({ name }) => name.toLowerCase() === countryName.toLowerCase());

  if (!country) return false;

  return country.enabled && country.addressLookup ? country.countryCode.toLowerCase() : false;
};

export const getCountryZipLength = (countryCode: string): { min: number; max: number } => {
  const country = Object.values(countryNames).find(
    ({ countryCode: code }) => code.toLowerCase() === countryCode.toLowerCase()
  );

  if (!country)
    return {
      min: 3,
      max: 10
    };

  return country.zipLength
    ? {
        min: country.zipLength.min,
        max: country.zipLength.max
      }
    : {
        min: 3,
        max: 10
      };
};
