"use client";

import styles from "./Dropdown.module.css";
import { ReactNode } from "react";
import { useFormData } from "@utils/Service/FormDataContext";

interface DropdownProps {
  title: ReactNode;
  children: ReactNode;
  index: number;
  query?: string;
  open?: boolean;
}

export default function Dropdown({ title, children, index, open = false }: DropdownProps) {
  const { checkErrors, componentIndex, setComponentIndex } = useFormData();

  const headerClasses = [styles.header, open ? styles.open : styles.hover].join(" ");

  const handleChangeComponent = () => {
    if (index < componentIndex) {
      setComponentIndex(index);
    } else {
      if (checkErrors(true)) {
        setComponentIndex(index);
      }
    }
  };

  return (
    <div className={styles.dropdown}>
      <div className={headerClasses} onClick={handleChangeComponent}>
        <span>{title}</span>
      </div>
      {open && <div className={styles.content}>{children}</div>}
    </div>
  );
}
