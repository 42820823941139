"use client";

// contexts/FormDataContext.tsx
import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import {
  Button,
  changeComplaintEntry,
  ComplaintEntryDefaultProps,
  ComplaintEntryLedStripProps,
  getComplaintEntry,
  isDefaultComplaintEntry,
  isLedStripComplaintEntry,
  JSONProduct,
  LedStripLengths,
  ServiceFormData
} from "@utils/OldService/Interfaces";
import { isServer } from "@utils/OldService/isServer";
import { CountryName } from "@utils/Country/countryEnums";
import { complaintEntriesSchema, contactInformation, serviceType } from "@utils/Mail/Schemas/service/complaintSchema";
import z from "zod";
import FormSetErrors from "@utils/Service/FormSetErrors";
import SessionStorage from "@utils/OldService/SessionStorage";

const defaultComplaintEntry: ComplaintEntryDefaultProps = {
  type: "default",
  productName: undefined,
  productNumber: undefined,
  productType: undefined,
  productData: undefined,
  invoiceNumber: undefined,
  quantity: 1,
  orderNumber: undefined,
  files: [],
  description: "",
  descriptionOfPlacement: ""
};

const defaultFormData: ServiceFormData = {
  serviceType: undefined,
  subject: undefined,
  zendeskParentId: undefined,
  company: undefined,
  contactPerson: undefined,
  email: undefined,
  phone: undefined,
  country: CountryName.Norway,
  address: undefined,
  zip: undefined,
  city: undefined,
  complaintEntries: [defaultComplaintEntry],
  treatmentPreference: undefined,
  estimatedWorkTime: undefined,
  message: undefined
};

// Create a context for the form data
const FormDataContext = createContext<
  | {
      formData: ServiceFormData;
      setFormData: (formData: ServiceFormData) => void;
      clearFormData: () => void;
      handleChange: (e: {
        target: {
          name: any;
          value: any;
        };
      }) => void;
      checkErrors: (checkAll?: boolean) => boolean;
      errors: { [key: string]: string };
      setErrors: (
        errors: { [key: string]: string } | ((prev: { [key: string]: string }) => { [key: string]: string })
      ) => void;
      getComplaintEntry: getComplaintEntry["getComplaintEntry"];
      addComplaintEntryDefault: () => void;
      addLedLength: (index: number) => void;
      removeComplaintEntryDefault: (index: number) => void;
      removeLedLength: (complaintIndex: number, lengthIndex: number) => void;
      changeComplaintEntry: changeComplaintEntry["changeComplaintEntry"];
      addFiles: ({
        index,
        lengthIndex,
        value
      }: {
        index: number;
        lengthIndex?: number;
        value: FileList | null;
      }) => void;
      removeFile: ({
        complaintIndex,
        fileIndex,
        lengthIndex
      }: {
        complaintIndex: number;
        fileIndex: number;
        lengthIndex?: number;
      }) => void;
      removeAllFiles: () => void;
      button: Button;
      componentIndex: number;
      setComponentIndex: (index: number) => void;
    }
  | undefined
>(undefined);

// Create a provider component for the form data
// important!: if the serviceFormData is updated-it will be saved to localstorage
// and would need to be cleared on page load-update the dates in file
// doesTheServiceDataNeedToBeClearedOnPageLoad.ts, which is used on ServiceForm.tsx
export function FormDataProvider({ children }: { children: ReactNode }) {
  const [formData, setFormData] = useState<ServiceFormData>(
    (SessionStorage.keyExists("serviceFormData") && SessionStorage.get("serviceFormData")) || defaultFormData
  );
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const [button] = useState<Button>({
    type: "button",
    text: "Neste",
    disabled: false
  });

  const [componentIndex, setComponentIndex] = useState<number>(1);

  useEffect(() => {
    if (!isServer()) {
      SessionStorage.set("serviceFormData", formData);
    }

    checkErrors();
  }, [formData]);

  const clearFormData = () => {
    setFormData(defaultFormData);
    SessionStorage.remove("serviceFormData");
  };

  const handleChange = (e: {
    target: {
      name: any;
      value: any;
    };
  }) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const checkErrors = (checkAll = false) => {
    setErrors({});

    try {
      switch (componentIndex) {
        case 1:
          serviceType.parse(formData);
          return true;
        case 2:
          contactInformation.parse(formData);
          return true;
        case 3:
          complaintEntriesSchema.parse(formData);
          return true;
        default:
          console.info("no match for current page");
          return false;
      }
    } catch (e) {
      if (e instanceof z.ZodError) {
        console.error(e.errors);

        FormSetErrors({
          e,
          checkAll,
          setErrors
        });
      }
    }

    return false;
  };

  const addComplaintEntryDefault = () => {
    // Copy all old complaint entries
    const oldComplaints = [...formData.complaintEntries];
    // Add a new complaint entry
    oldComplaints.push(defaultComplaintEntry);

    setFormData((prevState) => ({
      ...prevState,
      complaintEntries: oldComplaints
    }));
  };

  const addLedLength = (index: number) => {
    // Get a copy of the old complaint entries
    const oldComplaint = { ...getComplaintEntry(index) };

    if (isLedStripComplaintEntry(oldComplaint)) {
      if (!oldComplaint.lengths) {
        oldComplaint.lengths = [];
      }

      // Add a new length entry
      oldComplaint.lengths.push({
        driver: "",
        description: "",
        descriptionOfPlacement: "",
        length: undefined,
        lengthUnit: "cm",
        files: []
      });

      setFormData((prevState) => ({
        ...prevState,
        complaintEntries: prevState.complaintEntries.map((complaintEntry, i) => {
          if (i === index) {
            return oldComplaint;
          } else {
            return complaintEntry;
          }
        })
      }));
    }
  };

  const getComplaintEntry = (index: number | string) => {
    if (typeof index === "string") {
      index = parseInt(index);
    }
    return formData.complaintEntries[index];
  };

  const removeComplaintEntryDefault = (index: number) => {
    setFormData((prevState) => ({
      ...prevState,
      complaintEntries: prevState.complaintEntries.filter((complaintEntry, i) => i !== index)
    }));
  };

  const removeLedLength = (complaintIndex: number, lengthIndex: number) => {
    // Get a copy of the old complaint entries
    const oldComplaint = { ...getComplaintEntry(complaintIndex) };

    if (isLedStripComplaintEntry(oldComplaint)) {
      if (!oldComplaint.lengths) {
        return;
      }

      // Remove the length entry
      oldComplaint.lengths = oldComplaint.lengths.filter((length, i) => i !== lengthIndex);

      // Update the form data
      setFormData((prevState) => ({
        ...prevState,
        complaintEntries: prevState.complaintEntries.map((complaintEntry, i) => {
          if (i === complaintIndex) {
            return oldComplaint;
          } else {
            return complaintEntry;
          }
        })
      }));
    }
  };

  function changeComplaintType(
    newComplaintEntry: ComplaintEntryDefaultProps | ComplaintEntryLedStripProps,
    value: string,
    name: string
  ) {
    newComplaintEntry.productType = value;

    switch (value.toLowerCase()) {
      case "led strips":
        if (isDefaultComplaintEntry(newComplaintEntry)) {
          delete newComplaintEntry.description;
          delete newComplaintEntry.descriptionOfPlacement;
        }

        newComplaintEntry.type = "ledStrip";

        if (isLedStripComplaintEntry(newComplaintEntry) && !newComplaintEntry.lengths) {
          newComplaintEntry.lengths = [
            {
              description: "",
              descriptionOfPlacement: "",
              driver: "",
              length: undefined,
              lengthUnit: "cm",
              files: []
            }
          ];
        }

        break;
      default:
        if (isLedStripComplaintEntry(newComplaintEntry)) {
          delete newComplaintEntry.lengths;
        }

        newComplaintEntry.type = "default";

        if (isDefaultComplaintEntry(newComplaintEntry)) {
          newComplaintEntry.description = "";
          newComplaintEntry.descriptionOfPlacement = "";
        }

        // Set the new value
        (newComplaintEntry as any)[name] = value;

        break;
    }
  }

  const changeComplaintEntry = ({
    index,
    lengthIndex,
    name,
    type = "text",
    value,
    product
  }: {
    index: number;
    lengthIndex?: number;
    name: string;
    type: string;
    value: string | number;
    error?: string;
    product?: JSONProduct;
  }) => {
    // early return on product filter
    if (product) {
      const newComplaintEntry = { ...getComplaintEntry(index) };

      newComplaintEntry.productNumber = product.elNumber;
      newComplaintEntry.productName = product.productNameShort;
      newComplaintEntry.productType = product.productType;

      if (product.productType) {
        changeComplaintType(newComplaintEntry, product.productType, "productType");
      }

      // if (product.qNumber) {
      //   newComplaintEntry.orderNumber.value = product.qNumber;
      // }

      return setFormData((prevState) => ({
        ...prevState,
        complaintEntries: prevState.complaintEntries.map((complaintEntry, i) => {
          if (i === index) {
            return newComplaintEntry;
          } else {
            return complaintEntry;
          }
        })
      }));
    }

    if (type === "number") {
      if (typeof value === "string") {
        value = parseInt(value);
      }
    }
    // Copy the current complaint entry
    const newComplaintEntry = { ...getComplaintEntry(index) };

    // On product type change
    if (name === "productType" && typeof value === "string") {
      changeComplaintType(newComplaintEntry, value, name);
    } else if (isDefaultComplaintEntry(newComplaintEntry) || lengthIndex === undefined) {
      // Set the new value
      // TODO fix typing
      (newComplaintEntry as any)[name] = value;
    } else if (isLedStripComplaintEntry(newComplaintEntry) && lengthIndex !== undefined) {
      // Check if lengths in the complaint entry
      if (!newComplaintEntry.lengths) {
        newComplaintEntry.lengths = [
          {
            description: undefined,
            descriptionOfPlacement: undefined,
            driver: undefined,
            length: undefined,
            lengthUnit: "cm",
            files: []
          }
        ];
      }

      const lengthEntry = newComplaintEntry.lengths[lengthIndex];

      if (name === "accessories" || name === "images") {
        lengthEntry[name as keyof typeof lengthEntry] = value as any;
      } else {
        // TODO fix typing
        (lengthEntry as any)[name as keyof LedStripLengths] = value;
      }
    }

    // Update the form data
    setFormData((prevState) => ({
      ...prevState,
      complaintEntries: prevState.complaintEntries.map((complaintEntry, i) => {
        if (i === index) {
          return newComplaintEntry;
        } else {
          return complaintEntry;
        }
      })
    }));
  };

  const addFiles = ({ index, lengthIndex, value }: { index: number; lengthIndex?: number; value: FileList | null }) => {
    if (!value) return;

    const files = Array.from(value);

    // Copy the current complaint entry
    const newComplaintEntry = { ...getComplaintEntry(index) };

    // Set the new value
    if (lengthIndex === undefined) {
      newComplaintEntry.files = [...newComplaintEntry.files, ...files];
    }

    if (isLedStripComplaintEntry(newComplaintEntry) && lengthIndex !== undefined) {
      if (!newComplaintEntry.lengths || !newComplaintEntry.lengths[lengthIndex].files) {
        return;
      }

      newComplaintEntry.lengths[lengthIndex].files = [...newComplaintEntry.lengths[lengthIndex].files, ...files];
    }

    // Update the form datas
    setFormData((prevState) => ({
      ...prevState,
      complaintEntries: prevState.complaintEntries.map((complaintEntry, i) => {
        if (i === index) {
          return newComplaintEntry;
        } else {
          return complaintEntry;
        }
      })
    }));
  };

  const removeFile = ({
    complaintIndex,
    fileIndex,
    lengthIndex
  }: {
    complaintIndex: number;
    fileIndex: number;
    lengthIndex?: number;
  }) => {
    // Copy the current complaint entry
    const newComplaintEntry = { ...getComplaintEntry(complaintIndex) };

    // Set the new value
    if (lengthIndex === undefined) {
      // Check if files in the complaint entry
      if (!newComplaintEntry.files) {
        return;
      }

      // Filter out the file based on index not file
      newComplaintEntry.files = newComplaintEntry.files.filter((f, newFileIndex) => newFileIndex !== fileIndex);
    } else if (isLedStripComplaintEntry(newComplaintEntry) && lengthIndex !== undefined) {
      if (!newComplaintEntry.lengths || !newComplaintEntry.lengths[lengthIndex].files) {
        console.log("no files in lengths");
        return;
      }

      newComplaintEntry.lengths[lengthIndex].files = newComplaintEntry.lengths[lengthIndex].files.filter(
        (f, fileIndex) => fileIndex !== lengthIndex
      );
    } else {
      console.error("No files to remove");
    }

    // Update the form data
    setFormData((prevState) => ({
      ...prevState,
      complaintEntries: prevState.complaintEntries.map((complaintEntry, i) => {
        if (i === complaintIndex) {
          return newComplaintEntry;
        } else {
          return complaintEntry;
        }
      })
    }));
  };

  const removeAllFiles = () => {
    setFormData((prevState) => ({
      ...prevState,
      complaintEntries: prevState.complaintEntries.map((complaintEntry) => {
        if (isLedStripComplaintEntry(complaintEntry)) {
          return {
            ...complaintEntry,
            files: [],
            lengths: complaintEntry.lengths?.map((length) => ({
              ...length,
              images: []
            }))
          };
        } else {
          return {
            ...complaintEntry,
            files: []
          };
        }
      })
    }));
  };

  return (
    <FormDataContext.Provider
      value={{
        formData,
        setFormData,
        clearFormData,
        handleChange,
        checkErrors,
        errors,
        setErrors,
        getComplaintEntry,
        addComplaintEntryDefault,
        addLedLength,
        removeComplaintEntryDefault,
        removeLedLength,
        changeComplaintEntry,
        addFiles,
        removeFile,
        removeAllFiles,
        button,
        componentIndex,
        setComponentIndex
      }}
    >
      {children}
    </FormDataContext.Provider>
  );
}

// Create a custom hook to access the form data
export function useFormData() {
  const context = useContext(FormDataContext);
  if (context === undefined) {
    throw new Error("useFormData must be used within a FormDataProvider");
  }
  return context;
}
