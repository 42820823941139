export type ComplaintEntry = ComplaintEntryDefaultProps | ComplaintEntryLedStripProps;
export type ComplaintEntryKey = keyof ComplaintEntryDefaultProps | keyof ComplaintEntryLedStripProps;

export interface ComplaintEntryFormData {
  type: string;
  productName?: string;
  productNumber?: string;
  productType?: string;
  productData?: string;
  invoiceNumber?: string;
  quantity?: string | number;
  orderNumber?: string;
  files: globalThis.File[];
  description?: string;
  descriptionOfPlacement?: string;
}

export interface ComplaintEntryDefaultProps extends ComplaintEntryFormData {
  type: "default";
  descriptionOfPlacement?: string;
}

export interface ComplaintEntryLedStripProps extends ComplaintEntryFormData {
  type: "ledStrip";
  lengths?: LedStripLengths[];
}

export interface LedStripLengths {
  length?: number;
  lengthUnit?: string;
  description?: string;
  descriptionOfPlacement?: string;
  driver?: string;
  files?: globalThis.File[];
}

export interface ServiceFormData {
  serviceType?: string | undefined;
  subject?: string | undefined;
  zendeskParentId?: string | undefined;
  company?: string;
  contactPerson?: string;
  email?: string;
  phone?: string;
  country?: string;
  address?: string;
  zip?: string;
  city?: string;
  complaintEntries: ComplaintEntry[];
  treatmentPreference?: string;
  estimatedWorkTime?: number;
  message?: string;
}

export interface Button {
  type: "button" | "submit";
  text: string;
  disabled: boolean;
}

// if products is fetched from API, this JSONProduct interface should be changed or removed
export interface JSONProduct {
  elNumber?: string;
  qNumber?: string;
  brand?: string;
  productNameShort?: string;
  productFamily?: string;
  productType?: string;
}

export interface changeComplaintEntry {
  changeComplaintEntry: ({
    index,
    lengthIndex,
    name,
    type,
    value,
    product
  }: {
    index: number;
    lengthIndex?: number;
    name: string;
    type: string;
    value: string;
    product?: JSONProduct;
  }) => void;
}

export interface getComplaintEntry {
  getComplaintEntry: (index: string | number) => ComplaintEntry;
}

export const isDefaultComplaintEntry = (entry: ComplaintEntry): entry is ComplaintEntryDefaultProps =>
  entry.type === "default";

export const isLedStripComplaintEntry = (entry: ComplaintEntry): entry is ComplaintEntryLedStripProps =>
  entry.type === "ledStrip";
