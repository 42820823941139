import styles from "./Button.module.scss";
import { ReactNode } from "react";

interface ButtonProps {
  children: ReactNode;
  onClick?: () => void;
  id?: string;
  type: "button" | "submit";
  design?: "primary" | "secondary" | "sand" | "dark";
  disabled?: boolean;
  hover?: boolean;
  className?: string;
}

export default function Button({
  children,
  onClick,
  id,
  type = "button",
  design = "primary",
  disabled,
  hover = true,
  className
}: ButtonProps) {
  const classNames = [styles.button, styles[design], hover ? styles.hover : styles.disabled, className].join(" ");

  return (
    <button id={id} type={type} className={classNames} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
}
