import { isLedStripComplaintEntry, ServiceFormData } from "@utils/OldService/Interfaces";
import products from "@utils/Service/products.json";
import { ServiceJSONObject } from "@utils/Mail/Schemas/service/complaintSchema";
import { createServiceAction } from "@utils/Service/actions/createService";
import { ResponseStatus } from "@utils/OldService/enums";

export class ServiceHandler {
  private static _data: ServiceFormData | undefined = undefined;

  constructor(data: ServiceFormData) {
    ServiceHandler._data = data;
  }

  static getData(): ServiceFormData | undefined {
    return this._data;
  }

  static validateFormData(): void {
    ServiceJSONObject.parse(this._data);
  }

  static checkIfProductExistAndAddExtraProductData() {
    try {
      this._data?.complaintEntries.forEach((complaintEntry) => {
        const product = products.find((product) => product?.elNumber === complaintEntry.productNumber);

        if (!product) {
          if (complaintEntry.productData) delete complaintEntry.productData;
          return;
        }

        complaintEntry.productData = JSON.stringify({
          elNumber: product?.elNumber,
          qNumber: product?.qNumber,
          name: product?.productNameShort || product?.productNameLong,
          category: product?.productFamily
        });
      });
    } catch (error) {
      console.log(error, "adding extra productData field failed");
    }
  }

  static async submit() {
    if (!this._data) throw new Error("Data is undefined");

    const formData = new FormData();
    formData.append("exportData", JSON.stringify(this._data));

    // Assuming you have a nested structure with files
    this._data.complaintEntries.forEach((complaintEntry, index) => {
      complaintEntry.files?.forEach((file, fileIndex) => {
        formData.append(`${index}-${fileIndex}`, file as unknown as File);
      });

      if (isLedStripComplaintEntry(complaintEntry)) {
        complaintEntry.lengths?.forEach((length, lengthIndex) => {
          length.files?.forEach((file, fileIndex) => {
            formData.append(`${index}-${lengthIndex}-${fileIndex}`, file as unknown as File);
          });
        });
      }
    });

    const { data, status } = await createServiceAction(formData);

    if (status !== ResponseStatus.Success) {
      throw new Error("Something went wrong");
    }

    return { data, status: ResponseStatus.Success };
  }
}
