import styles from "./Form.module.scss";
import useTranslations from "@utils/Localization/useTranslations";
import { useClientLocale } from "@utils/Localization/useClientLocale";
import { useFormData } from "@utils/Service/FormDataContext";
import Input from "@components/OldService/UI/Input/Input";

export default function AdditionalInformation() {
  const { formData, errors, handleChange } = useFormData();

  const { t } = useTranslations(useClientLocale());

  const label =
    formData.serviceType === "complaint" ? t("treatmentPreferenceTitleComplaint") : t("treatmentPreferenceTitleReturn");

  const complaintOptions = [
    {
      value: "",
      label: t("chooseOneOfTheOptions"),
      disabled: true
    },
    {
      value: "replacement",
      label: t("newItem")
    },
    {
      value: "replacementReceived",
      label: t("receivedReplacement")
    },
    {
      value: "support",
      label: `${t("support")}`
    },
    {
      value: "credit",
      label: t("creditNonReplacementItem")
    }
  ];

  const returnOptions = [
    {
      value: "",
      label: t("chooseOneOfTheOptions"),
      disabled: true
    },
    // {
    //   value: "replacement",
    //   label: t("requestForADifferentItem")
    // },
    {
      value: "credit",
      label: t("returnOfItemAndCreditNote")
    }
  ];

  const select = formData.serviceType === "complaint" ? complaintOptions : returnOptions;

  return (
    <div className={styles.content}>
      <Input
        type={"select"}
        name={"treatmentPreference"}
        label={label}
        select={select}
        onChange={handleChange}
        value={formData.treatmentPreference}
        error={errors["treatmentPreference"] && t(errors["treatmentPreference"])}
        required
      />
      <Input
        name={"estimatedWorkTime"}
        type={"number"}
        number={{ min: 0, max: 1000, step: 0.5 }}
        label={t("estimatedElectricianWorkHours")}
        onChange={handleChange}
        value={formData.estimatedWorkTime}
        error={errors["estimatedWorkTime"] && t(errors["estimatedWorkTime"])}
      />
      <Input
        type={"textarea"}
        textArea={{
          rows: 10
        }}
        name={"message"}
        label={t("message")}
        onChange={handleChange}
        value={formData.message}
      />
    </div>
  );
}
