import products from "./products.json";
import useTranslations from "@utils/Localization/useTranslations";
import { Locale } from "@ts-types/locale";
import { convertToCamelCase } from "@utils/Text/utils";

export const productTypesSelectValues = Array.from(new Set(products.map((product) => product.productType))).map(
  (productType) => {
    return {
      label: productType,
      value: productType
    };
  }
);

export const getProductTypes = (locale: Locale) => {
  const { t } = useTranslations(locale);

  return Array.from(new Set(products.map((product) => product.productType))).map((productType) => {
    return {
      label: t(convertToCamelCase(productType)),
      value: productType
    };
  });
};

export enum ProductType {
  elNumber = "elNumber",
  qNumber = "qNumber",
  brand = "brand",
  productNameShort = "productNameShort",
  productFamily = "productFamily",
  productType = "productType"
}
