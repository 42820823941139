"use client";
import products from "@utils/Service/products.json";
import { useEffect, useState } from "react";
import { useFormData } from "@utils/Service/FormDataContext";
import { JSONProduct } from "@utils/OldService/Interfaces";
import Input from "@components/OldService/UI/Input/Input";

export default function FilterProducts({
  name,
  index,
  label,
  value,
  error,
  className
}: {
  name: string;
  index: number;
  label: string;
  value?: string;
  error?: string | null;
  className: string;
}) {
  const { changeComplaintEntry } = useFormData();

  const [search, setSearch] = useState(value || "");
  const [filteredProducts, setFilteredProducts] = useState<JSONProduct[]>(products);

  const searchProducts = (e: {
    target: {
      name: string;
      value: string;
      type?: string;
    };
  }) => {
    const value = e.target.value;

    setSearch(value);

    if (value.length < 3) {
      return;
    }

    // search matching products
    const matchingProducts: JSONProduct[] = products.filter((product) => {
      // includes any of the search terms
      const searchTerms = value.toLowerCase();

      const productString = JSON.stringify(product).toLowerCase();

      return productString.includes(searchTerms);
    });

    setFilteredProducts(matchingProducts);

    if (matchingProducts.length === 1) {
      changeComplaintEntry({
        index: index,
        name: "",
        value: "",
        type: "product",
        product: matchingProducts[0]
      });

      return;
    }

    changeComplaintEntry({
      index: index,
      name: "productNumber",
      value: value,
      type: "text"
    });
  };

  useEffect(() => {
    setSearch(value || "");
  }, [value]);

  return (
    <div className={className}>
      <Input
        className={className}
        name={name}
        label={label}
        onChange={searchProducts}
        value={search}
        error={error}
        autoComplete={false}
        list={`products-${index}`}
      />
      {filteredProducts.length > 0 && (
        <datalist id={`products-${index}`}>
          {filteredProducts.map(({ elNumber, productNameShort, qNumber }, i) => (
            <option key={i} value={elNumber}>
              {qNumber} - {productNameShort}
            </option>
          ))}
        </datalist>
      )}
    </div>
  );
}
