export const pages = {
  index: "/",
  content: "/content",
  service: "/service",
  dashboard: "/admin/service/dashboard",
  admin: {
    dashboard: "/admin/service/dashboard",
    service: "/admin/service",
    career: "/admin/service/career",
    applicants: "/admin/service/career/applicants",
    jobListings: "/admin/service/career/job-listings",
    manageCareer: "/admin/service/career",
    faq: "admin/faq"
  },
  login: "/auth/login",
  preview: "/admin/preview",
  data: "/admin/data",
  career: "/career"
} as const;
