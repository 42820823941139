import { z } from "zod";
import { Request } from "@utils/Mail/Schemas/utils/request";
import { fileSchema } from "@utils/Mail/Schemas/utils/fileSchema";

const phoneRegex = new RegExp("^\\+?[-0-9\\s]*$");
const zipRegex = new RegExp("^[0-9]*$");
const zendeskTicketIdRegex = new RegExp("^[0-9]*$");

export const serviceType = z.object({
  serviceType: z.string({
    required_error: "requiredServiceType"
  }),
  subject: z.string({
    required_error: "requiredSubject"
  }),
  zendeskParentId: z
    .string()
    .regex(zendeskTicketIdRegex, {
      message: "invalidZendeskTicketId"
    })
    .optional()
});

export const contactInformation = z.object({
  company: z
    .string({
      required_error: "requiredCompany"
    })
    .min(2, {
      message: "requiredMin2Length"
    }),
  contactPerson: z
    .string({
      required_error: "requiredContactPerson"
    })
    .min(3, {
      message: "requiredMin3Length"
    }),
  country: z
    .string({
      required_error: "requiredCountry"
    })
    .min(2, {
      message: "requiredMin2Length"
    }),
  email: z
    .string({
      required_error: "requiredEmail"
    })
    .email({
      message: "invalidEmail"
    }),
  phone: z
    .string({
      required_error: "requiredPhone"
    })
    .min(4, {
      message: "requiredMin4Length"
    })
    .regex(phoneRegex, {
      message: "invalidPhoneNumber"
    }),
  address: z
    .string({
      required_error: "requiredAddress"
    })
    .min(2, {
      message: "requiredMin2Length"
    }),
  zip: z
    .string({
      required_error: "requiredZip"
    })
    .min(4, {
      message: "requiredMin4Length"
    })
    .regex(zipRegex, {
      message: "requiredZipFormat"
    }),
  city: z
    .string({
      required_error: "requiredCity"
    })
    .min(2, {
      message: "requiredMin2Length"
    })
});

export const lengthSchema = z.object({
  length: z
    .number({
      required_error: "requiredLength",
      invalid_type_error: "invalid"
    })
    .min(1, {
      message: "requiredMin1Length"
    })
    .positive(),
  lengthUnit: z.string({
    required_error: "requiredLengthUnit"
  }),
  description: z
    .string({
      required_error: "requiredFaultDescription"
    })
    .min(2, {
      message: "requiredMin2Length"
    }),
  descriptionOfPlacement: z
    .string({
      required_error: "requiredPlacementDescription"
    })
    .min(2, {
      message: "requiredMin2Length"
    }),
  accessories: z.string().optional(),
  driver: z.string({
    required_error: "requiredDriver"
  }),
  files: z.array(fileSchema).nonempty({
    message: "required"
  })
});

export const complaintEntryFormDataSchema = z.object({
  productType: z.string({
    required_error: "requiredProductType"
  }),
  productName: z.string({
    required_error: "requiredProductName"
  }),
  productNumber: z
    .string({
      required_error: "requiredProductNumber"
    })

    .optional(),
  productData: z.string().optional(),
  quantity: z
    .number({
      required_error: "requiredQuantity"
    })
    .positive(),
  files: z.array(fileSchema).optional(),
  description: z
    .string({
      required_error: "requiredFaultDescription"
    })
    .min(2, {
      message: "requiredMin2Length"
    })
    .optional(),
  placement: z.string().optional(),
  lengths: z.array(lengthSchema).optional()
});

const complaintEntryExtended = complaintEntryFormDataSchema
  .extend({
    orderNumber: z
      .string({
        required_error: "requiredOrderNumber"
      })
      .regex(zipRegex, {
        message: "invalid"
      })
      .optional(),
    invoiceNumber: z
      .string({
        required_error: "requiredInvoiceNumber"
      })
      .regex(zipRegex, {
        message: "invalid"
      })
      .optional()
  })
  .superRefine((data, ctx) => {
    const hasOrderNumber = data.orderNumber && data.orderNumber.trim() !== "";
    const hasInvoiceNumber = data.invoiceNumber && data.invoiceNumber.trim() !== "";

    if (!hasOrderNumber && !hasInvoiceNumber) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["orderNumber"],
        message: "issueEitherOrderOrInvoiceNumber"
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["invoiceNumber"],
        message: "issueEitherOrderOrInvoiceNumber"
      });
    }

    if (hasOrderNumber && data.orderNumber?.length !== 5) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["orderNumber"],
        message: "requiredOrderNumberLength"
      });
    }
  });

export const complaintEntrySchema = complaintEntryExtended.refine(
  (data) => {
    if (data.productType === "default") {
      return !("lengths" in data);
    }
    if (data.productType === "ledStrip") {
      return !("files" in data) && !("description" in data) && !("descriptionOfPlacement" in data);
    }
    return true;
  },
  {
    message: "invalid",
    path: ["type"]
  }
);

export const complaintEntriesSchema = z.object({
  complaintEntries: z.array(complaintEntrySchema)
});

export const additionalInformation = z.object({
  message: z.string().optional(),
  estimatedWorkTime: z.number().or(z.string()).optional(),
  treatmentPreference: z
    .string({
      required_error: "requiredTreatmentPreference"
    })
    .optional()
});

export const ServiceJSONObject = z.object({
  uuid: z.string().optional(),
  ...serviceType.shape,
  ...contactInformation.shape,
  ...complaintEntriesSchema.shape,
  ...additionalInformation.shape
});

// extend with request
export const ComplaintRequest = Request.and(
  z.object({
    data: ServiceJSONObject
  })
);

export type ComplaintRequestType = z.infer<typeof ComplaintRequest>;
