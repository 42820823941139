import styles from "./ComplaintEntry.module.scss";
import { ChangeEvent } from "react";

import useTranslations from "@utils/Localization/useTranslations";
import { useClientLocale } from "@utils/Localization/useClientLocale";
import { ComplaintEntryDefaultProps, ComplaintEntryLedStripProps } from "@utils/OldService/Interfaces";
import { useFormData } from "@utils/Service/FormDataContext";
import Input from "@components/OldService/UI/Input/Input";
import { getProductTypes } from "@utils/Service/productsHelper";
import FilterProducts from "@components/OldService/Service/Form/FilterProducts";
import Button from "@components/OldService/UI/Button/Button";
import Spacer from "@components/OldService/UI/Spacer/Spacer";

export default function ComplaintEntry({
  complaintEntry,
  index
}: {
  complaintEntry: ComplaintEntryDefaultProps | ComplaintEntryLedStripProps;
  index: number;
}) {
  const {
    errors,
    getComplaintEntry,
    addLedLength,
    removeComplaintEntryDefault,
    removeLedLength,
    changeComplaintEntry,
    addFiles,
    removeFile
  } = useFormData();

  const { t } = useTranslations(useClientLocale());

  const onChange = (
    e: {
      target: {
        name: string;
        value: string;
        type?: string;
      };
    },
    lengthIndex?: number
  ) => {
    changeComplaintEntry({
      index: index,
      lengthIndex: lengthIndex,
      name: e.target.name.split("-")[0], // remove the index from the name
      value: e.target.value,
      type: e.target.type || "text"
    });
  };

  const onFileChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    lengthIndex?: number
  ) => {
    // cast to HTMLInputElement
    const files = (e.target as HTMLInputElement).files;

    addFiles({
      index: index,
      value: files,
      lengthIndex
    });

    // reset the input
    (e.target as HTMLInputElement).value = "";
  };

  const onRemoveFile = (fileIndex: number, lengthIndex?: number) => {
    const complaintEntry = getComplaintEntry(index);

    if ("files" in complaintEntry && lengthIndex === undefined) {
      // Check if the file was found before calling removeFile
      removeFile({
        complaintIndex: index,
        fileIndex
      });
    } else if ("lengths" in complaintEntry && lengthIndex !== undefined && complaintEntry.lengths) {
      removeFile({
        complaintIndex: index,
        fileIndex,
        lengthIndex
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {index > 0 && (
          <button
            id={`removeComplaint-${index}`}
            type={"button"}
            className={styles.removeComplaintEntry}
            onClick={() => removeComplaintEntryDefault(index)}
          >
            x
          </button>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.order}>
          <Input
            name={`orderNumber-${index}`}
            label={`Q-Light ${t("orderNumber")}`}
            onChange={onChange}
            value={complaintEntry.orderNumber || ""}
            error={errors[`orderNumber-${index}`] && t(errors[`orderNumber-${index}`])}
          />
          <div className={styles.evt}>
            {t("and").toLowerCase()} / {t("or").toLowerCase()}
          </div>
          <Input
            name={`invoiceNumber-${index}`}
            label={`Q-Light ${t("invoiceNumber")}`}
            onChange={onChange}
            value={complaintEntry.invoiceNumber}
            error={errors[`invoiceNumber-${index}`] && t(errors[`invoiceNumber-${index}`])}
          />
        </div>
        <div className={styles.complaintEntry}>
          <Input
            name={`quantity-${index}`}
            type={"number"}
            label={t("quantity")}
            onChange={onChange}
            className={styles.quantity}
            value={`${complaintEntry.quantity}`}
            error={errors[`quantity-${index}`] && t(errors[`quantity-${index}`])}
            required
          />
          <FilterProducts
            name={`productNumber-${index}`}
            index={index}
            value={complaintEntry.productNumber}
            error={errors[`productNumber-${index}`] && t(errors[`productNumber-${index}`])}
            label={t("productNumber")}
            className={styles.productName}
          />
          <Input
            name={`productName-${index}`}
            label={t("productName")}
            onChange={onChange}
            className={styles.productName}
            value={complaintEntry.productName}
            error={errors[`productName-${index}`] && t(errors[`productName-${index}`])}
            required
          />
          <Input
            name={`productType-${index}`}
            type={"select"}
            label={t("category")}
            onChange={onChange}
            className={styles.productName}
            value={complaintEntry.productType}
            error={errors[`productType-${index}`] && t(errors[`productType-${index}`])}
            select={[
              {
                value: "",
                label: t("chooseProductType"),
                disabled: true
              },
              ...getProductTypes(useClientLocale())
            ]}
            required
          />
        </div>
        {"description" in complaintEntry && (
          <Input
            name={`description-${index}`}
            label={t("descriptionOfFault")}
            type={"textarea"}
            textArea={{
              rows: 5
            }}
            onChange={onChange}
            className={styles.productName}
            value={complaintEntry.description}
            error={errors[`description-${index}`] && t(errors[`description-${index}`])}
            required
          />
        )}

        {"descriptionOfPlacement" in complaintEntry && (
          <Input
            name={`descriptionOfPlacement-${index}`}
            label={t("descriptionOfPlacement")}
            aside={`(${t("e.g.").toLowerCase()} ${t("livingRoom").toLowerCase()}, ${t("wetRoom").toLowerCase()}, ${t(
              "outdoorRoom"
            ).toLowerCase()}, ${t("balcony").toLowerCase()})`}
            onChange={onChange}
            className={styles.productName}
            value={complaintEntry.descriptionOfPlacement}
            error={errors[`descriptionOfPlacement-${index}`] && t(errors[`descriptionOfPlacement-${index}`])}
          />
        )}

        {/*TODO Make dynamically assigned*/}
        {"files" in complaintEntry && complaintEntry.files && (
          <div className={styles.fileContainer}>
            <Input
              type={"file"}
              name={`file-${index}`}
              label={t("uploadFiles")}
              accept="*camera"
              aside={`(${t("fileExampleText").toLowerCase()})`}
              file={{
                multiple: true,
                label: t("chooseFile")
              }}
              onChange={onFileChange}
            />
            <div className={styles.files}>
              {complaintEntry.files &&
                complaintEntry.files.length > 0 &&
                complaintEntry.files.map((file: any, fileIndex: number) => (
                  <div key={fileIndex} className={styles.file}>
                    <button
                      id={`removeFile-${fileIndex}`}
                      type={"button"}
                      onClick={() => onRemoveFile(fileIndex)}
                      className={styles.removeFile}
                    >
                      x
                    </button>
                    <p>{file.name}</p>
                  </div>
                ))}
            </div>
          </div>
        )}

        {"lengths" in complaintEntry && complaintEntry.lengths && complaintEntry.lengths.length > 0 && (
          <div id={"ledLengths"}>
            <h3 className={styles.title}>{t("ledStrips")}</h3>
            <p>{t("fillInLedStripLength")}</p>

            <Spacer />

            {complaintEntry.lengths.map((length, lengthIndex) => (
              <div id={`ledLength-${index}-${lengthIndex}`} key={lengthIndex} className={styles.lengthsCard}>
                <div className={styles.header}>
                  {lengthIndex > 0 && (
                    <button
                      id={`removeLedLength-${lengthIndex}`}
                      type={"button"}
                      className={styles.removeComplaintEntry}
                      onClick={() => removeLedLength(index, lengthIndex)}
                    >
                      x
                    </button>
                  )}
                </div>

                <div className={styles.lengthRow}>
                  <Input
                    type={"number"}
                    name={`length-${index}-${lengthIndex}`}
                    label={`${t("ledLength")} (cm)`}
                    onChange={(e) => onChange(e, lengthIndex)}
                    value={length.length || ""}
                    error={errors[`length-${index}-${lengthIndex}`] && t(errors[`length-${index}-${lengthIndex}`])}
                    required
                  />

                  <Input
                    name={`driver-${index}-${lengthIndex}`}
                    label={`${t("ledDriver")} - ${t("productNumber")}/${t("articleNumber")}/${t("elNumber")}`}
                    onChange={(e) => onChange(e, lengthIndex)}
                    className={styles.productName}
                    value={length.driver}
                    error={errors[`driver-${index}-${lengthIndex}`] && t(errors[`driver-${index}-${lengthIndex}`])}
                    required
                  />

                  {"description" in length && (
                    <Input
                      name={`description-${index}-${lengthIndex}`}
                      label={t("descriptionOfFault")}
                      type={"textarea"}
                      textArea={{
                        rows: 5
                      }}
                      onChange={(e) => onChange(e, lengthIndex)}
                      className={styles.productName}
                      value={length.description}
                      error={
                        errors[`description-${index}-${lengthIndex}`] &&
                        t(errors[`description-${index}-${lengthIndex}`])
                      }
                      required
                    />
                  )}

                  {"descriptionOfPlacement" in length && (
                    <Input
                      name={`descriptionOfPlacement-${index}-${lengthIndex}`}
                      label={`${t("descriptionOfPlacement")} ${t("of").toLowerCase()} LED ${t("strip").toLowerCase()}`}
                      aside={`(${t("e.g.").toLowerCase()} ${t("livingRoom").toLowerCase()}, ${t(
                        "wetRoom"
                      ).toLowerCase()}, ${t("outdoorRoom").toLowerCase()}, ${t("balcony").toLowerCase()})`}
                      onChange={(e) => onChange(e, lengthIndex)}
                      className={styles.productName}
                      value={length.descriptionOfPlacement}
                      error={
                        errors[`descriptionOfPlacement-${index}-${lengthIndex}`] &&
                        t(errors[`descriptionOfPlacement-${index}-${lengthIndex}`])
                      }
                      required
                    />
                  )}
                </div>

                <div className={styles.fileContainer}>
                  <Input
                    type={"file"}
                    name={`files-${index}-${lengthIndex}`}
                    label={t("uploadFiles")}
                    aside={`(${t("fileExampleText").toLowerCase()})`}
                    file={{
                      multiple: true,
                      label: t("chooseFile")
                    }}
                    accept="image/*camera"
                    error={errors[`files-${index}-${lengthIndex}`] && t(errors[`files-${index}-${lengthIndex}`])}
                    onChange={(e) => onFileChange(e, lengthIndex)}
                    required
                  />
                  <div className={styles.files}>
                    {length.files &&
                      length.files &&
                      Array.isArray(length.files) &&
                      length.files.length > 0 &&
                      length.files.map((image: globalThis.File, fileIndex: number) => (
                        <div key={fileIndex} className={styles.file}>
                          <button
                            id={`removeFile-${fileIndex}`}
                            type={"button"}
                            onClick={() => onRemoveFile(fileIndex, lengthIndex)}
                            className={styles.removeFile}
                          >
                            x
                          </button>
                          <p>{image.name}</p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ))}

            <div className={styles.add}>
              <Button id={"addNewProduct"} type={"button"} design={"secondary"} onClick={() => addLedLength(index)}>
                + {t("addNewLedLength")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
