import styles from "./Form.module.scss";
import useTranslations from "@utils/Localization/useTranslations";
import { useClientLocale } from "@utils/Localization/useClientLocale";
import { useFormData } from "@utils/Service/FormDataContext";
import { getCity } from "@utils/Service/getAddress";
import Input from "@components/OldService/UI/Input/Input";
import { countrySelectOptions, getCountryByEnabledAndHasAddressLookup } from "@utils/OldService/countryList";

export default function ContactInformation() {
  const { formData, errors, handleChange } = useFormData();

  const { t } = useTranslations(useClientLocale());

  const addressZipLookup = async (e: {
    target: {
      name: any;
      value: any;
    };
  }) => {
    handleChange(e);

    const country = formData.country;

    if (country) {
      const countryCode = getCountryByEnabledAndHasAddressLookup(country);
      if (countryCode) {
        const cityAddress = await getCity(e.target.value, countryCode);
        if (cityAddress) {
          if (cityAddress.postal_codes.length === 1) {
            const city = cityAddress.postal_codes[0].city;
            handleChange({
              target: {
                name: "city",
                value: city
              }
            });
          }
        }
      }
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.grid2}>
        <Input
          name={"company"}
          label={t("company")}
          onChange={handleChange}
          value={formData.company}
          error={errors["company"] && t(errors["company"])}
          required
        />
        <Input
          name={"contactPerson"}
          label={t("contactPerson")}
          onChange={handleChange}
          value={formData.contactPerson}
          error={errors["contactPerson"] && t(errors["contactPerson"])}
          required
        />
        <Input
          name={"email"}
          label={t("email")}
          onChange={handleChange}
          value={formData.email}
          error={errors["email"] && t(errors["email"])}
          required
        />
        <Input
          name={"phone"}
          label={t("phone")}
          onChange={handleChange}
          value={formData.phone}
          error={errors["phone"] && t(errors["phone"])}
          required
        />
        <div className={styles.addressZipCity}>
          <Input
            name={"country"}
            label={t("country")}
            type={"select"}
            select={countrySelectOptions}
            onChange={handleChange}
            value={formData.country}
            error={errors["country"] && t(errors["country"])}
            required
          />
          <Input
            name={"address"}
            label={t("address")}
            onChange={handleChange}
            value={formData.address}
            error={errors["address"] && t(errors["address"])}
            required
          />
        </div>
        <div className={styles.addressZipCity}>
          <Input
            name={"zip"}
            label={t("zipCode")}
            onChange={addressZipLookup}
            value={formData.zip}
            error={errors["zip"] && t(errors["zip"])}
            required
          />
          <Input
            name={"city"}
            label={t("city")}
            onChange={handleChange}
            value={formData.city}
            error={errors["city"] && t(errors["city"])}
            required
          />
        </div>
      </div>
    </div>
  );
}
