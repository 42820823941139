import styles from "./Form.module.scss";
import useTranslations from "@utils/Localization/useTranslations";
import { useClientLocale } from "@utils/Localization/useClientLocale";
import { useFormData } from "@utils/Service/FormDataContext";
import { serviceType, subject } from "@utils/Service/formConstants";
import Input from "@components/OldService/UI/Input/Input";

export default function Service() {
  const { formData, errors, handleChange } = useFormData();

  const { t } = useTranslations(useClientLocale());

  const radioType = serviceType.options.map((option) => ({
    value: option.value,
    checked: formData.serviceType === option.value,
    label: option.key ? t(option.key) : option.label
  }));

  const radioSubject = subject.options.map((option) => ({
    value: option.value,
    checked: formData.subject === option.value,
    label: option.key ? t(option.key) : option.label
  }));

  return (
    <div className={styles.content}>
      <Input
        type={"radio"}
        name={"serviceType"}
        label={t("chooseCause") + " - " + t("serviceWholesalerNote")}
        radio={radioType}
        onChange={handleChange}
        value={formData.serviceType}
        error={errors["serviceType"] && t(errors["serviceType"])}
        required
      />
      <Input
        type={"radio"}
        name={"subject"}
        label={t("pickSubject")}
        radio={radioSubject}
        onChange={handleChange}
        value={formData.subject}
        error={errors["subject"] && t(errors["subject"])}
        required
      />
      <div className={styles.grid3}>
        <Input
          type={"text"}
          name={"zendeskParentId"}
          label={t("zendeskTicketId")}
          onChange={handleChange}
          value={formData.zendeskParentId}
          error={errors["zendeskParentId"] && t(errors["zendeskParentId"])}
        />
      </div>
    </div>
  );
}
