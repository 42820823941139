import * as React from "react";

interface SpacerProps {
  height?: string;
  underline?: boolean;
}

const Spacer = ({ height = "30px", underline = false }: SpacerProps) => {
  if (underline) {
    return (
      <div>
        <div
          style={{
            height: height
          }}
        />
        <div
          style={{
            height: "1px",
            background: underline && "black",
            backgroundColor: underline && "black"
          }}
        />
        <div
          style={{
            height: height
          }}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        height: height
      }}
    />
  );
};

export default Spacer;
