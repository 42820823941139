export const serviceType: {
  name: string;
  label: string;
  options: { value: string; label: string; key?: string }[];
} = {
  name: "serviceType",
  label: "Velg årsak",
  options: [
    { value: "return", label: "Retur", key: "return" },
    { value: "complaint", label: "Reklamasjon", key: "complaint" }
  ]
};

export interface subject {
  name: string;
  label: string;
  options: { value: string; label: string; key?: string }[];
}

export const subject: subject = {
  name: "subject",
  label: "Velg emne",
  options: [
    {
      value: "lighting",
      label: "Belysning",
      key: "lighting"
    },
    {
      value: "qudo",
      label: "QUDO"
    },
    {
      value: "onepole",
      label: "ONEPOLE"
    }
  ]
};

export const returnOptions = [
  {
    value: "",
    label: "Velg et av alternativene",
    key: "chooseOneOfTheOptions",
    disabled: true
  },
  {
    value: "replacement",
    label: "Ønsker annen vare",
    key: "requestForADifferentItem"
  },
  {
    value: "credit",
    label: "Retur av vare og kredit nota",
    key: "returnOfItemAndCreditNote"
  }
];

export const complaintOptions = [
  {
    value: "",
    label: "Velg et av alternativene",
    key: "chooseOneOfTheOptions",
    disabled: true
  },
  {
    value: "replacement",
    label: "Ønsker erstatning",
    key: "requestForReplacement"
  },
  {
    value: "support",
    label: "Support",
    key: "support"
  },
  {
    value: "credit",
    label: "Kreditt (ikke erstatningsvare)",
    key: "creditNonReplacementItem"
  },
  {
    value: "replacementReceived",
    label: "Har fått erstatning",
    key: "receivedReplacement"
  }
];
