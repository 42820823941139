import { Locale } from "@ts-types/locale";
import loadTranslations from "./loadTranslations";

// const debug = false;

export const getTranslation = (locale: Locale, key: string): { [p: string]: string } => {
  const translations = loadTranslations(locale);

  if (translations === undefined) {
    return {
      [key]: key
    };
  }

  return {
    [key]: translations[key]
  };
};
